import { render, staticRenderFns } from "./LogoutForm.vue?vue&type=template&id=f8ee3c72&"
import script from "./LogoutForm.vue?vue&type=script&lang=js&"
export * from "./LogoutForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/utb1-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f8ee3c72')) {
      api.createRecord('f8ee3c72', component.options)
    } else {
      api.reload('f8ee3c72', component.options)
    }
    module.hot.accept("./LogoutForm.vue?vue&type=template&id=f8ee3c72&", function () {
      api.rerender('f8ee3c72', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/auth/LogoutForm.vue"
export default component.exports